<template>
  <div>
    <v-row class="match-height">
      <v-col
        cols="12"
      >
        <sync-overview></sync-overview>
      </v-col>
      <v-col
        cols="12"
      >
        <sync-overview-list></sync-overview-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SyncOverview from './SyncOverviewCard.vue'
import SyncOverviewList from './SyncOverviewList.vue'

export default {
  components: {
    SyncOverview,
    SyncOverviewList,
  },
}
</script>
