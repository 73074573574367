<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="7"
          class="sync-chart-col"
        >
          <v-card-title class="px-0 pt-0">
            Sync Overview
            <v-chip class="ml-3">
              Today
            </v-chip>
          </v-card-title>
          <vue-apex-charts
            id="sync-chart"
            height="200"
            :options="chartOptions"
            :series="chartData"
          ></vue-apex-charts>
        </v-col>

        <v-col
          cols="12"
          md="5"
        >
          <v-card
            elevation="0"
          >
            <!-- Title/Header -->
            <v-card-title class="align-start pt-0 flex-nowrap">
              <div>
                <p class="mb-0 font-weight-bold text-2xl">
                  Total Sync Events: 8.8K
                </p>
                <!-- <small class="text--secondary text-xs text-no-wrap">Last month balance $234.40k</small> -->
              </div>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n7 mt-n1"
              >
                <v-icon size="22">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-0 pt-3">
              <!-- List -->
              <v-list
                two-line
                subheader
              >
                <!-- List Item: Success -->
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    class="v-avatar-light-bg success--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-icon
                      size="30"
                      color="success"
                    >
                      {{ icons.mdiCheckBold }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      7.5K
                    </v-list-item-title>
                    <v-list-item-subtitle>Succeeded Events</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- List Item: Failures -->
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    class="v-avatar-light-bg error--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-icon
                      size="30"
                      color="error"
                    >
                      {{ icons.mdiExclamationThick }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      1.3K
                    </v-list-item-title>
                    <v-list-item-subtitle>Failed Events</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiCurrencyUsd, mdiChartBar, mdiCheckBold, mdiExclamationThick } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const $vuetify = getVuetify()

    const chartOptions = {
      colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.error],
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '35%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: ['7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: value => kFormatter(value, 0),
        },
      },
      grid: {
        strokeDashArray: 7,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 6,
        lineCap: 'round',
        colors: ['#fff'],
      },
      responsive: [
        {
          breakpoint: 1650,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '45%',
              },
            },
          },
        },
      ],
    }

    const chartData = [
      {
        name: 'SUCCEEDED',
        data: [290, 220, 250, 190, 300, 210, 350],
      },
      {
        name: 'FAILED',
        data: [0, 160, 110, 150, 0, 120, 90],
      },
    ]

    return {
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
        mdiChartBar,
        mdiCheckBold,
        mdiExclamationThick,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .sync-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(sync-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>
