<template>
  <v-card>
    <v-card-title class="align-start">
      <v-icon
        left
        color="info"
      >
        {{ icons.mdiSnowflake }}
      </v-icon>
      <span style="padding-top: 2px">Snowflake Connectors</span>
      <v-spacer></v-spacer>
      <!-- Action Button -->
      <v-btn
        small
        color="primary"
        depressed
        class="mr-2"
        :to="{ name: 'create-binding' }"
      >
        <v-icon
          dark
          left
        >
          {{ icons.mdiPlus }}
        </v-icon>
        New Snow Binding
      </v-btn>
      <v-btn
        icon
        small
        :to="{ name: 'edit-binding', params: { id: 1641606424415 } }"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-simple-table class="connector-table">
      <thead>
        <tr>
          <th class="text-left">
            Sync Name
          </th>
          <th class="text-left">
            Status
          </th>
          <th class="text-left">
            Last Run
          </th>
          <th class="text-left">
            Progress
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="connector in connectors"
          :key="connector.title"
          height="80px"
        >
          <td class="d-flex mt-3">
            <v-avatar size="60">
              <v-img :src="connector.avatar"></v-img>
            </v-avatar>
            <div class="text-no-wrap ms-3 mt-2">
              <p class="font-weight-medium mb-0 text--primary">
                {{ connector.title }}
              </p>
              <span class="text-xs">{{ connector.subtitle }}</span>
            </div>
          </td>
          <td>
            <v-chip
              small
              color="primary"
              :class="`v-chip-light-bg ${connector.chipColor}--text font-weight-semibold`"
            >
              {{ connector.chipText }}
            </v-chip>
          </td>
          <td>
            <p class="font-weight-semibold mb-0">
              <span class="primary--text">{{ connector.lastRun | moment("from") }}</span>
            </p>
          </td>
          <td class="d-flex align-center justify-center">
            <vue-apex-charts
              id="sync-chart"
              height="100"
              width="600"
              :options="chartOptions"
              :series="chartData"
            ></vue-apex-charts>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiSnowflake, mdiPlus } from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'
import { getVuetify } from '@core/utils'

// import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const $vuetify = getVuetify()

    const chartData = [
      {
        name: 'SUCCEEDED',
        data: [290, 220, 250, 190, 300, 0, 0, 290, 220, 250, 190, 300, 210, 350],
      },
      {
        name: 'FAILED',
        data: [0, 0, 0, 0, 0, 120, 90, 0, 0, 0, 0, 0, 0, 0],
      },
    ]

    const chartOptions = {
      colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.error],
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          columnWidth: '35%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: [
          '7AM',
          '8AM',
          '9AM',
          '10AM',
          '11AM',
          '12PM',
          '1PM',
          '1PM',
          '2PM',
          '3PM',
          '4PM',
          '5PM',
          '6PM',
          '7PM',
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 0,
        lineCap: 'round',
        colors: ['#fff'],
      },

      // responsive: [
      //   {
      //     breakpoint: 2000,
      //     options: {
      //       plotOptions: {
      //         bar: {
      //           borderRadius: 5,
      //           columnWidth: '35%',
      //         },
      //       },
      //     },
      //   },
      // ],
    }

    // const chartOptions = {
    //   chart: {
    //     sparkline: {
    //       enabled: false,
    //     },
    //   },
    //   plotOptions: {
    //     radialBar: {
    //       hollow: {
    //         size: '30%',
    //       },
    //       track: {
    //         background: '#ebe9f1',
    //       },
    //       dataLabels: {
    //         show: false,
    //         name: {
    //           show: false,
    //         },
    //       },
    //     },
    //   },

    //   grid: {
    //     padding: {
    //       bottom: -15,
    //     },
    //   },
    // }

    const connectors = [
      {
        avatar: require('@/assets/images/logos/GongLogoSmall.png'),
        title: 'Send Gong Calls',
        subtitle: 'Gong Connector',
        chipText: 'Healthy',
        chipColor: 'success',
        lastRun: new Date(),
        series: [65],
        chartColor: '#9155fd',
      },
      {
        avatar: require('@/assets/images/logos/YahooAdsLogoSmall.png'),
        title: 'Yahoo Conversions',
        subtitle: 'Yahoo Ads Connector',
        chipText: 'Healthy',
        chipColor: 'success',
        lastRun: new Date() - 600000,
        series: [75],
        chartColor: '#56ca00',
      },
      {
        avatar: require('@/assets/images/logos/GoogleAdsLogoSmall.png'),
        title: 'Google Ads Conversions',
        subtitle: 'Google Ads Connector',
        chipText: 'Warnings',
        chipColor: 'warning',
        lastRun: new Date() - 6000000,
        series: [55],
        chartColor: '#ffb400',
      },
      {
        avatar: require('@/assets/images/logos/GoogleSheetsLogoSmall.png'),
        title: 'Customer Sheet Sync',
        subtitle: 'Google Sheets Connector',
        chipText: 'Failed',
        chipColor: 'error',
        lastRun: new Date() - 7000000,
        series: [60],
        chartColor: '#ff4c51',
      },
      {
        avatar: require('@/assets/images/logos/FacebookAdsLogoSmall.png'),
        title: 'Facebook Ad Conversions',
        subtitle: 'Facebook Ads Connector',
        chipText: 'Disabled',
        chipColor: 'secondary',
        lastRun: new Date() - 700000000,
        series: [60],
        chartColor: '#656971',
      },
      {
        avatar: require('@/assets/images/logos/BingAdsLogoSmall.png'),
        title: 'Bing Ad Conversions',
        subtitle: 'Bing Ads Connector',
        chipText: 'Disabled',
        chipColor: 'secondary',
        lastRun: new Date() - 6000000000,
        series: [60],
        chartColor: '#656971',
      },
    ]

    const getChartConfig = value => {
      const options = JSON.parse(JSON.stringify(chartOptions))
      options.colors = [value]

      return options
    }

    return {
      connectors,
      chartData,
      chartOptions,
      getChartConfig,
      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiSnowflake,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.connector-table {
  tbody {
    td {
      border-bottom: none !important;
    }
  }
}
</style>
